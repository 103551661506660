#main-header {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
  transition-property: top;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  @media (max-width: 1023px) {
    top: 30px;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 60px;
    transition-property: padding;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    @media (max-width: 1023px) {
      padding: 0 25px;
    }

    .left {
      &-logo a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        pointer-events: initial;
        width: 92px;
        height: 38px;
        transition-property: background-color, width, height;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        backdrop-filter: blur(22px);

        img {
          display: inline-block;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      &-actions {
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
          display: none;
        }

        .action {
          background-color: rgba(0, 0, 0, 0.15);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          pointer-events: initial;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          backdrop-filter: blur(22px);

          &:not(:last-child) {
            margin-right: 10px;
          }

          &-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
          }

          &-icon {
            position: relative;
            z-index: 1;
          }
        }
      }

      &-menu {
        margin-left: 40px;
        flex-shrink: 0;

        @media (max-width: 1023px) {
          margin: 0;
        }

        .menu-toggler {
          background-color: rgba(0, 0, 0, 0.15);
          position: relative;
          width: 145px;
          height: 60px;
          pointer-events: initial;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          backdrop-filter: blur(22px);

          @media (max-width: 1023px) {
            background-color: transparent;
            width: 60px;
            height: 60px;
          }

          span {
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 2px;

            &::before {
              content: '';
              background-color: color(yellow);
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transform-origin: 100% 50%;
              transition-property: transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;

              @media (max-width: 1023px) {
                background-color: white;
              }
            }

            &:nth-child(1) {
              top: calc(50% - 7px);

              &::before {
                transform: scaleX(0.5);
              }
            }

            &:nth-child(2) {
              top: 50%;

              &::before {
                transform: scaleX(1);
              }
            }

            &:nth-child(3) {
              top: calc(50% + 7px);

              &::before {
                transform: scaleX(0.25);
              }
            }
          }
        }
      }
    }
  }

  &.sticky {
    top: 0;

    &:not([theme='black']) {
      .flex-wrapper {
        .left {
          &-logo a {
            background-color: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }

    .flex-wrapper {
      padding: 0;

      .left {
        &-logo a {
          background-color: rgba(0, 0, 0, 0.15);
          width: 150px;
          height: 60px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    &[theme='black'] .flex-wrapper {
      .right {
        &-actions .action {
          svg path {
            fill: black;
          }
        }

        &-menu .menu-toggler {
          span::before {
            background-color: black;
          }
        }
      }
    }
  }
}
