.project {
  background-color: #464646;
  position: relative;
  width: 100%;
  height: 314px;
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: 1023px) {
    height: 495px;
  }

  &-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  &-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    img {
      @extend %cover;
    }
  }

  &-title {
    color: white;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
    z-index: 2;
  }
}
