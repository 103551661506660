#main-menu {
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 100;

  @media (max-width: 1023px) {
    background-color: transparent;
  }

  .logo {
    position: absolute;
    top: 70px;
    left: 60px;
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }

    a {
      display: inline-block;
    }
  }

  .close {
    background-color: fade-out(white, 0.85);
    position: absolute;
    top: 60px;
    right: 0;
    width: 145px;
    height: 60px;
    padding: 2px 0 0;

    @media (max-width: 1023px) {
      background-color: unset;
      top: 30px;
      right: 25px;
      width: unset;
      height: unset;
      opacity: 0;
      transition-property: opacity;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
      z-index: 5;
    }

    svg path {
      fill: white;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 160px;
    bottom: 0;

    @media (max-width: 1023px) {
      background-color: black;
      justify-content: flex-start;
      right: 0;
      padding: 110px 25px 0 70px;
      transform: translateX(100%);
      transition-property: transform, box-shadow;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
      box-shadow: -20px 0px 32px rgba(21, 21, 21, 0);
    }

    &::before {
      content: '';
      background-color: fade-out(white, 0.85);
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      width: 1px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .line {
      background-color: color(red);
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      width: 1px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .navigation {
      ul {
        li {
          @media (max-width: 1023px) {
            text-align: right;
            opacity: 0;
            transform: translateX(15px);
            transition-property: opacity, transform;
            transition-duration: time(slow);
            transition-timing-function: ease;
          }

          &:not(:last-child) {
            margin-bottom: 30px;

            @media (max-width: 1023px) {
              margin-bottom: 20px;
            }
          }

          a {
            color: color(content);
            font-size: 36px;
            font-weight: 300;

            @media (max-width: 1023px) {
              color: white;
              font-size: 28px;
              font-weight: 800;
            }
          }
        }
      }
    }

    .actions {
      position: absolute;
      right: 25px;
      bottom: 110px;

      .action {
        position: relative;
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(15px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease;

        &:not(:last-child) {
          @media (max-width: 1023px) {
            margin-bottom: 30px;
          }
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
          text-indent: -999px;
        }

        &-title {
          color: white;
          font-size: 14px;
          font-weight: normal;
          margin-right: 24px;

          strong {
            font-weight: bold;
          }
        }

        &-icon {
          width: 26px;
          text-align: center;
          flex-shrink: 0;
        }
      }
    }

    .social {
      position: absolute;
      bottom: 30px;
      left: 0;

      @media (max-width: 1023px) {
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        left: unset;
        right: 25px;
      }

      ul li {
        a {
          display: inline-block;

          svg path {
            fill: color(content);

            @media (max-width: 1023px) {
              fill: white;
            }
          }
        }
      }
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .close {
      @media (max-width: 1023px) {
        opacity: 1;
        transition-delay: 0.2s;
      }
    }

    .wrapper {
      @media (max-width: 1023px) {
        transform: translateX(0);
        box-shadow: -20px 0px 32px rgba(21, 21, 21, 0.5);
      }

      .navigation ul li {
        @media (max-width: 1023px) {
          opacity: 1;
          transform: translateX(0);

          @for $i from 0 to 8 {
            &:nth-child(#{$i}) {
              transition-delay: 0.1s * $i + 0.1s;
            }
          }
        }
      }

      .actions .action {
        opacity: 1;
        transform: translateX(0);

        &:nth-child(1) {
          transition-delay: 0.7s;
        }

        &:nth-child(2) {
          transition-delay: 0.8s;
        }
      }

      .social {
        @media (max-width: 1023px) {
          opacity: 1;
          transition-delay: 0.9s;
        }
      }
    }
  }
}
