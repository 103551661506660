[rel='lazy_load'] {
  overflow: hidden;

  @keyframes lazy_load {
    0% {
      transform: scaleX(0);
      transform-origin: 0% 50%;
    }
    50% {
      transform: scaleX(1);
      transform-origin: 0% 50%;
    }
    50.1% {
      transform: scaleX(1);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  &:not(.no-animate) {
    &::before {
      @extend %cover;

      content: '';
      background-color: color(orange);
      transform: scaleX(0);
      z-index: 2;
    }

    img {
      z-index: 1;
      transform-origin: 25% 25%;
      opacity: 0;
      transform: scale(1.05);
      transition-property: opacity, transform;
      transition-duration: 2s;
      transition-timing-function: ease(inout);
    }
  }

  &.no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 2s;
    transition-timing-function: ease(inout);
  }

  &.loaded {
    &:not(.no-animate) {
      &::before {
        animation-name: lazy_load;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: ease(inout);
      }

      img {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.7s;
      }
    }

    &.no-animate img {
      opacity: 1;
    }
  }
}
