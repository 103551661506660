.button {
  position: relative;
  width: auto;
  height: 60px;
  border: 1px solid color(yellow);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 30px 0;

  svg {
    position: absolute;
    top: 50%;
    left: calc(100% - 18px);
    transform: translateY(-50%);
    z-index: 1;
  }

  span {
    color: white;
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
  }

  &.button-red {
    border-color: color(red);

    svg path {
      stroke: color(red);
    }

    span {
      color: color(red);
    }
  }
}
