/**
 * Vars
 */
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

form.form .field-group[data-type='file'] .upload-box label, .project-image img, [rel='lazy_load']:not(.no-animate)::before, .el-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

/**
* Base.
*/
body {
  font-family: 'Montserrat';
  font-size: 16px; }
  body::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 195;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms; }
  body.shadow {
    width: 100%;
    overflow: hidden; }
    body.shadow::before {
      opacity: 0.6;
      pointer-events: initial; }
  body.modal-open, body.menu-open {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1300px) {
    .container {
      padding: 0 25px; } }

* {
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

form input {
  line-height: initial; }

form input,
form textarea,
form select {
  outline: none !important; }
  form input:active, form input:focus,
  form textarea:active,
  form textarea:focus,
  form select:active,
  form select:focus {
    outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

.screen-progress {
  background-color: #f3dc02;
  color: black;
  text-align: center;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: black;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: 0.5s; }

.button {
  position: relative;
  width: auto;
  height: 60px;
  border: 1px solid #f3dc02;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 30px 0; }
  .button svg {
    position: absolute;
    top: 50%;
    left: calc(100% - 18px);
    transform: translateY(-50%);
    z-index: 1; }
  .button span {
    color: white;
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block; }
  .button.button-red {
    border-color: #e6273a; }
    .button.button-red svg path {
      stroke: #e6273a; }
    .button.button-red span {
      color: #e6273a; }

form.form .form-flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px; }
  @media (max-width: 1023px) {
    form.form .form-flex {
      grid-template-columns: 1fr;
      grid-row-gap: 20px; } }
  form.form .form-flex .field-group {
    margin: 0 !important; }
  form.form .form-flex:not(:last-child) {
    margin-bottom: 20px; }

form.form .field-group {
  position: relative; }
  form.form .field-group:not(:last-child) {
    margin-bottom: 20px; }
  form.form .field-group .label {
    color: white;
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
    transition-property: color;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group .label span {
      color: white; }
  form.form .field-group.focus .label,
  form.form .field-group.not-empty .label {
    color: #f3dc02; }
  form.form .field-group.invalid .label {
    border-color: red; }
  form.form .field-group[data-type='text'] .field {
    background-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition-property: border-color;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='text'] .field::placeholder {
      color: rgba(255, 255, 255, 0.5);
      transition-property: opacity, transform;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  form.form .field-group[data-type='text'] textarea {
    font-family: 'Montserrat';
    line-height: 1.4;
    height: 150px !important;
    resize: none;
    padding: 20px !important; }
  form.form .field-group[data-type='text'].focus .field,
  form.form .field-group[data-type='text'].not-empty .field {
    border-color: white; }
    form.form .field-group[data-type='text'].focus .field::placeholder,
    form.form .field-group[data-type='text'].not-empty .field::placeholder {
      opacity: 0;
      transform: translateX(5px); }
  form.form .field-group[data-type='text'].invalid .field {
    border-color: red; }
  form.form .field-group[data-type='select'] .field, form.form .field-group[data-type='select-options'] .field {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  form.form .field-group[data-type='select'] .box, form.form .field-group[data-type='select-options'] .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 20px;
    transition-property: border-color;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='select'] .box span, form.form .field-group[data-type='select-options'] .box span {
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      opacity: 0.5;
      width: calc(100% - 20px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      transition-property: opacity, color;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='select'] .box svg, form.form .field-group[data-type='select-options'] .box svg {
      flex-shrink: 0;
      margin-left: 20px;
      transition-property: transform;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  form.form .field-group[data-type='select'] .dropdown, form.form .field-group[data-type='select-options'] .dropdown {
    background-color: white;
    overflow: auto;
    max-height: 180px;
    opacity: 0;
    transform: translateY(10px);
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    transition-property: opacity, transform;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='select'] .dropdown ul li, form.form .field-group[data-type='select-options'] .dropdown ul li {
      display: block; }
      form.form .field-group[data-type='select'] .dropdown ul li button, form.form .field-group[data-type='select-options'] .dropdown ul li button {
        color: #151515;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 100%;
        height: 40px;
        padding: 0 20px;
        transition-property: color, background-color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        form.form .field-group[data-type='select'] .dropdown ul li button:hover, form.form .field-group[data-type='select-options'] .dropdown ul li button:hover {
          background-color: #151515;
          color: white; }
  form.form .field-group[data-type='select'].invalid .box, form.form .field-group[data-type='select-options'].invalid .box {
    border-color: red; }
  form.form .field-group[data-type='select'].not-empty .box, form.form .field-group[data-type='select-options'].not-empty .box {
    border-color: white; }
    form.form .field-group[data-type='select'].not-empty .box span, form.form .field-group[data-type='select-options'].not-empty .box span {
      opacity: 1; }
  form.form .field-group[data-type='select'].active .box svg, form.form .field-group[data-type='select-options'].active .box svg {
    transform: rotate(180deg); }
  form.form .field-group[data-type='select'].active .dropdown, form.form .field-group[data-type='select-options'].active .dropdown {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial; }
  form.form .field-group[data-type='options'] .field {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  form.form .field-group[data-type='options'] .options {
    display: flex;
    align-items: center;
    height: 50px; }
    @media (max-width: 1023px) {
      form.form .field-group[data-type='options'] .options {
        flex-direction: column;
        align-items: flex-start; } }
    form.form .field-group[data-type='options'] .options .option:not(:last-child) {
      margin-right: 45px; }
      @media (max-width: 1023px) {
        form.form .field-group[data-type='options'] .options .option:not(:last-child) {
          margin: 0 0 20px; } }
    form.form .field-group[data-type='options'] .options .option button {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: 500;
      position: relative;
      transition-property: color;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      padding-left: 30px; }
      form.form .field-group[data-type='options'] .options .option button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 1px solid #cccccc;
        border-radius: 50%;
        transition-property: background-color, border-color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      form.form .field-group[data-type='options'] .options .option button::after {
        content: '';
        background-color: white;
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='options'] .options .option.active button {
      color: white; }
      form.form .field-group[data-type='options'] .options .option.active button::before {
        background-color: #f3dc02;
        border-color: #f3dc02; }
      form.form .field-group[data-type='options'] .options .option.active button::after {
        opacity: 1; }
  form.form .field-group[data-type='file'] .upload-box {
    position: relative;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition-property: border-color;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    form.form .field-group[data-type='file'] .upload-box input {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: -1; }
    form.form .field-group[data-type='file'] .upload-box label {
      cursor: pointer;
      z-index: 5; }
    form.form .field-group[data-type='file'] .upload-box span {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      text-align: left; }
    form.form .field-group[data-type='file'] .upload-box svg path {
      fill: white; }

form.form.form-grey .field-group .label {
  color: #151515; }
  form.form.form-grey .field-group .label span {
    color: #e6273a; }

form.form.form-grey .field-group.focus .label,
form.form.form-grey .field-group.not-empty .label {
  color: #e6273a; }

form.form.form-grey .field-group.invalid .label {
  border-color: red; }

form.form.form-grey .field-group[data-type='text'] .field {
  color: #151515;
  border-color: #cccccc; }
  form.form.form-grey .field-group[data-type='text'] .field::placeholder {
    color: rgba(21, 21, 21, 0.2);
    opacity: 1; }

form.form.form-grey .field-group[data-type='text'].focus .field,
form.form.form-grey .field-group[data-type='text'].not-empty .field {
  border-color: #151515; }
  form.form.form-grey .field-group[data-type='text'].focus .field::placeholder,
  form.form.form-grey .field-group[data-type='text'].not-empty .field::placeholder {
    opacity: 0;
    transform: translateX(5px); }

form.form.form-grey .field-group[data-type='text'].invalid .field {
  border-color: red; }

form.form.form-grey .field-group[data-type='select'] .box, form.form.form-grey .field-group[data-type='select-options'] .box {
  border-color: #cccccc; }
  form.form.form-grey .field-group[data-type='select'] .box span, form.form.form-grey .field-group[data-type='select-options'] .box span {
    color: rgba(21, 21, 21, 0.2);
    opacity: 1; }
  form.form.form-grey .field-group[data-type='select'] .box svg path, form.form.form-grey .field-group[data-type='select-options'] .box svg path {
    fill: #151515; }

form.form.form-grey .field-group[data-type='select'].invalid .box, form.form.form-grey .field-group[data-type='select-options'].invalid .box {
  border-color: red; }

form.form.form-grey .field-group[data-type='select'].not-empty .box, form.form.form-grey .field-group[data-type='select-options'].not-empty .box {
  border-color: #151515; }
  form.form.form-grey .field-group[data-type='select'].not-empty .box span, form.form.form-grey .field-group[data-type='select-options'].not-empty .box span {
    color: #151515;
    opacity: 1; }

form.form.form-grey .field-group[data-type='options'] .options .option button {
  color: #cccccc; }

form.form.form-grey .field-group[data-type='options'] .options .option.active button {
  color: #151515; }
  form.form.form-grey .field-group[data-type='options'] .options .option.active button::before {
    background-color: #e6273a;
    border-color: #e6273a; }

form.form.form-grey .field-group[data-type='file'] .upload-box {
  border-color: #cccccc; }
  form.form.form-grey .field-group[data-type='file'] .upload-box span {
    color: rgba(21, 21, 21, 0.2); }

.project {
  background-color: #464646;
  position: relative;
  width: 100%;
  height: 314px;
  border-radius: 6px;
  overflow: hidden; }
  @media (max-width: 1023px) {
    .project {
      height: 495px; } }
  .project-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5; }
  .project-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .project-title {
    color: white;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
    z-index: 2; }

[rel='lazy_load'] {
  overflow: hidden; }

@keyframes lazy_load {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 0% 50%; }
  50.1% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }
  [rel='lazy_load']:not(.no-animate)::before {
    content: '';
    transform: scaleX(0);
    z-index: 2; }
  [rel='lazy_load']:not(.no-animate) img {
    z-index: 1;
    transform-origin: 25% 25%;
    opacity: 0;
    transform: scale(1.05);
    transition-property: opacity, transform;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].loaded:not(.no-animate)::before {
    animation-name: lazy_load;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].loaded:not(.no-animate) img {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.7s; }
  [rel='lazy_load'].loaded.no-animate img {
    opacity: 1; }

/**
* Globals.
*/
#main-header {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
  transition-property: top;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  @media (max-width: 1023px) {
    #main-header {
      top: 30px; } }
  #main-header .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 60px;
    transition-property: padding;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    @media (max-width: 1023px) {
      #main-header .flex-wrapper {
        padding: 0 25px; } }
    #main-header .flex-wrapper .left-logo a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      pointer-events: initial;
      width: 92px;
      height: 38px;
      transition-property: background-color, width, height;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      backdrop-filter: blur(22px); }
      #main-header .flex-wrapper .left-logo a img {
        display: inline-block; }
    #main-header .flex-wrapper .right {
      display: flex;
      align-items: center; }
      #main-header .flex-wrapper .right-actions {
        display: flex;
        align-items: center; }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .right-actions {
            display: none; } }
        #main-header .flex-wrapper .right-actions .action {
          background-color: rgba(0, 0, 0, 0.15);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          pointer-events: initial;
          transition-property: background-color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          backdrop-filter: blur(22px); }
          #main-header .flex-wrapper .right-actions .action:not(:last-child) {
            margin-right: 10px; }
          #main-header .flex-wrapper .right-actions .action-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2; }
          #main-header .flex-wrapper .right-actions .action-icon {
            position: relative;
            z-index: 1; }
      #main-header .flex-wrapper .right-menu {
        margin-left: 40px;
        flex-shrink: 0; }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .right-menu {
            margin: 0; } }
        #main-header .flex-wrapper .right-menu .menu-toggler {
          background-color: rgba(0, 0, 0, 0.15);
          position: relative;
          width: 145px;
          height: 60px;
          pointer-events: initial;
          transition-property: background-color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          backdrop-filter: blur(22px); }
          @media (max-width: 1023px) {
            #main-header .flex-wrapper .right-menu .menu-toggler {
              background-color: transparent;
              width: 60px;
              height: 60px; } }
          #main-header .flex-wrapper .right-menu .menu-toggler span {
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 2px; }
            #main-header .flex-wrapper .right-menu .menu-toggler span::before {
              content: '';
              background-color: #f3dc02;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transform-origin: 100% 50%;
              transition-property: transform;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
              z-index: 1; }
              @media (max-width: 1023px) {
                #main-header .flex-wrapper .right-menu .menu-toggler span::before {
                  background-color: white; } }
            #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(1) {
              top: calc(50% - 7px); }
              #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(1)::before {
                transform: scaleX(0.5); }
            #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(2) {
              top: 50%; }
              #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(2)::before {
                transform: scaleX(1); }
            #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(3) {
              top: calc(50% + 7px); }
              #main-header .flex-wrapper .right-menu .menu-toggler span:nth-child(3)::before {
                transform: scaleX(0.25); }
  #main-header.sticky {
    top: 0; }
    #main-header.sticky:not([theme='black']) .flex-wrapper .left-logo a {
      background-color: rgba(0, 0, 0, 0.15); }
    #main-header.sticky .flex-wrapper {
      padding: 0; }
      #main-header.sticky .flex-wrapper .left-logo a {
        background-color: rgba(0, 0, 0, 0.15);
        width: 150px;
        height: 60px; }
  @media (min-width: 1024px) {
    #main-header[theme='black'] .flex-wrapper .right-actions .action svg path {
      fill: black; }
    #main-header[theme='black'] .flex-wrapper .right-menu .menu-toggler span::before {
      background-color: black; } }

#main-menu {
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 100; }
  @media (max-width: 1023px) {
    #main-menu {
      background-color: transparent; } }
  #main-menu .logo {
    position: absolute;
    top: 70px;
    left: 60px;
    z-index: 1; }
    @media (max-width: 1023px) {
      #main-menu .logo {
        display: none; } }
    #main-menu .logo a {
      display: inline-block; }
  #main-menu .close {
    background-color: rgba(255, 255, 255, 0.15);
    position: absolute;
    top: 60px;
    right: 0;
    width: 145px;
    height: 60px;
    padding: 2px 0 0; }
    @media (max-width: 1023px) {
      #main-menu .close {
        background-color: unset;
        top: 30px;
        right: 25px;
        width: unset;
        height: unset;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
        z-index: 5; } }
    #main-menu .close svg path {
      fill: white; }
  #main-menu .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 160px;
    bottom: 0; }
    @media (max-width: 1023px) {
      #main-menu .wrapper {
        background-color: black;
        justify-content: flex-start;
        right: 0;
        padding: 110px 25px 0 70px;
        transform: translateX(100%);
        transition-property: transform, box-shadow;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
        box-shadow: -20px 0px 32px rgba(21, 21, 21, 0); } }
    #main-menu .wrapper::before {
      content: '';
      background-color: rgba(255, 255, 255, 0.15);
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      width: 1px; }
      @media (max-width: 1023px) {
        #main-menu .wrapper::before {
          display: none; } }
    #main-menu .wrapper .line {
      background-color: #e6273a;
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      width: 1px; }
      @media (max-width: 1023px) {
        #main-menu .wrapper .line {
          display: none; } }
    @media (max-width: 1023px) {
      #main-menu .wrapper .navigation ul li {
        text-align: right;
        opacity: 0;
        transform: translateX(15px);
        transition-property: opacity, transform;
        transition-duration: 1s;
        transition-timing-function: ease; } }
    #main-menu .wrapper .navigation ul li:not(:last-child) {
      margin-bottom: 30px; }
      @media (max-width: 1023px) {
        #main-menu .wrapper .navigation ul li:not(:last-child) {
          margin-bottom: 20px; } }
    #main-menu .wrapper .navigation ul li a {
      color: #808080;
      font-size: 36px;
      font-weight: 300; }
      @media (max-width: 1023px) {
        #main-menu .wrapper .navigation ul li a {
          color: white;
          font-size: 28px;
          font-weight: 800; } }
    #main-menu .wrapper .actions {
      position: absolute;
      right: 25px;
      bottom: 110px; }
      #main-menu .wrapper .actions .action {
        position: relative;
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(15px);
        transition-property: opacity, transform;
        transition-duration: 1s;
        transition-timing-function: ease; }
        @media (max-width: 1023px) {
          #main-menu .wrapper .actions .action:not(:last-child) {
            margin-bottom: 30px; } }
        #main-menu .wrapper .actions .action-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
          text-indent: -999px; }
        #main-menu .wrapper .actions .action-title {
          color: white;
          font-size: 14px;
          font-weight: normal;
          margin-right: 24px; }
          #main-menu .wrapper .actions .action-title strong {
            font-weight: bold; }
        #main-menu .wrapper .actions .action-icon {
          width: 26px;
          text-align: center;
          flex-shrink: 0; }
    #main-menu .wrapper .social {
      position: absolute;
      bottom: 30px;
      left: 0; }
      @media (max-width: 1023px) {
        #main-menu .wrapper .social {
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          left: unset;
          right: 25px; } }
      #main-menu .wrapper .social ul li a {
        display: inline-block; }
        #main-menu .wrapper .social ul li a svg path {
          fill: #808080; }
          @media (max-width: 1023px) {
            #main-menu .wrapper .social ul li a svg path {
              fill: white; } }
  #main-menu.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial; }
    @media (max-width: 1023px) {
      #main-menu.open .close {
        opacity: 1;
        transition-delay: 0.2s; } }
    @media (max-width: 1023px) {
      #main-menu.open .wrapper {
        transform: translateX(0);
        box-shadow: -20px 0px 32px rgba(21, 21, 21, 0.5); } }
    @media (max-width: 1023px) {
      #main-menu.open .wrapper .navigation ul li {
        opacity: 1;
        transform: translateX(0); }
        #main-menu.open .wrapper .navigation ul li:nth-child(0) {
          transition-delay: 0.1s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(1) {
          transition-delay: 0.2s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(2) {
          transition-delay: 0.3s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(3) {
          transition-delay: 0.4s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(4) {
          transition-delay: 0.5s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(5) {
          transition-delay: 0.6s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(6) {
          transition-delay: 0.7s; }
        #main-menu.open .wrapper .navigation ul li:nth-child(7) {
          transition-delay: 0.8s; } }
    #main-menu.open .wrapper .actions .action {
      opacity: 1;
      transform: translateX(0); }
      #main-menu.open .wrapper .actions .action:nth-child(1) {
        transition-delay: 0.7s; }
      #main-menu.open .wrapper .actions .action:nth-child(2) {
        transition-delay: 0.8s; }
    @media (max-width: 1023px) {
      #main-menu.open .wrapper .social {
        opacity: 1;
        transition-delay: 0.9s; } }

/**
 *Cover.
 */
