form.form {
  .form-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    .field-group {
      margin: 0 !important;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .field-group {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .label {
      color: white;
      font-size: 12px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
      transition-property: color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      span {
        color: white;
      }
    }

    &.focus .label,
    &.not-empty .label {
      color: color(yellow);
    }

    &.invalid .label {
      border-color: red;
    }

    &[data-type='text'] {
      .field {
        background-color: transparent;
        color: white;
        font-size: 14px;
        font-weight: 500;
        display: block;
        width: 100%;
        height: 50px;
        padding: 0 20px;
        border: 1px solid fade-out(white, 0.8);
        transition-property: border-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &::placeholder {
          color: fade-out(white, 0.5);
          transition-property: opacity, transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      textarea {
        font-family: 'Montserrat';
        line-height: 1.4;
        height: 150px !important;
        resize: none;
        padding: 20px !important;
      }

      &.focus .field,
      &.not-empty .field {
        border-color: white;

        &::placeholder {
          opacity: 0;
          transform: translateX(5px);
        }
      }

      &.invalid .field {
        border-color: red;
      }
    }

    &[data-type='select'],
    &[data-type='select-options'] {
      .field {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 50px;
        border: 1px solid fade-out(white, 0.8);
        padding: 0 20px;
        transition-property: border-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        span {
          color: white;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          opacity: 0.5;
          width: calc(100% - 20px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
          transition-property: opacity, color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }

        svg {
          flex-shrink: 0;
          margin-left: 20px;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      .dropdown {
        background-color: white;
        overflow: auto;
        max-height: 180px;
        opacity: 0;
        transform: translateY(10px);
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
        transition-property: opacity, transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        ul {
          li {
            display: block;

            button {
              color: color(black);
              font-size: 16px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              text-align: left;
              width: 100%;
              height: 40px;
              padding: 0 20px;
              transition-property: color, background-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                background-color: color(black);
                color: white;
              }
            }
          }
        }
      }

      &.invalid .box {
        border-color: red;
      }

      &.not-empty .box {
        border-color: white;

        span {
          opacity: 1;
        }
      }

      &.active {
        .box svg {
          transform: rotate(180deg);
        }

        .dropdown {
          opacity: 1;
          transform: translateY(0);
          pointer-events: initial;
        }
      }
    }

    &[data-type='options'] {
      .field {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      .options {
        display: flex;
        align-items: center;
        height: 50px;

        @media (max-width: 1023px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .option {
          &:not(:last-child) {
            margin-right: 45px;

            @media (max-width: 1023px) {
              margin: 0 0 20px;
            }
          }

          button {
            color: fade-out(white, 0.5);
            font-size: 14px;
            font-weight: 500;
            position: relative;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            padding-left: 30px;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -0;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              border: 1px solid #cccccc;
              border-radius: 50%;
              transition-property: background-color, border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &::after {
              content: '';
              background-color: white;
              position: absolute;
              top: 50%;
              left: 8px;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              border-radius: 50%;
              opacity: 0;
              transition-property: opacity;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }
          }

          &.active button {
            color: white;

            &::before {
              background-color: color(yellow);
              border-color: color(yellow);
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    &[data-type='file'] {
      .upload-box {
        position: relative;
        height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid fade-out(white, 0.8);
        transition-property: border-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          z-index: -1;
        }

        label {
          @extend %cover;

          cursor: pointer;
          z-index: 5;
        }

        span {
          color: fade-out(white, 0.5);
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          text-align: left;
        }

        svg path {
          fill: white;
        }
      }
    }
  }

  &.form-grey {
    .field-group {
      .label {
        color: color(black);

        span {
          color: color(red);
        }
      }

      &.focus .label,
      &.not-empty .label {
        color: color(red);
      }

      &.invalid .label {
        border-color: red;
      }

      &[data-type='text'] {
        .field {
          color: color(black);
          border-color: #cccccc;

          &::placeholder {
            color: fade-out(color(black), 0.8);
            opacity: 1;
          }
        }

        &.focus .field,
        &.not-empty .field {
          border-color: color(black);

          &::placeholder {
            opacity: 0;
            transform: translateX(5px);
          }
        }

        &.invalid .field {
          border-color: red;
        }
      }

      &[data-type='select'],
      &[data-type='select-options'] {
        .box {
          border-color: #cccccc;

          span {
            color: fade-out(color(black), 0.8);
            opacity: 1;
          }

          svg path {
            fill: color(black);
          }
        }

        &.invalid .box {
          border-color: red;
        }

        &.not-empty .box {
          border-color: color(black);

          span {
            color: color(black);
            opacity: 1;
          }
        }
      }

      &[data-type='options'] {
        .options .option {
          button {
            color: #cccccc;
          }

          &.active button {
            color: color(black);

            &::before {
              background-color: color(red);
              border-color: color(red);
            }
          }
        }
      }

      &[data-type='file'] {
        .upload-box {
          border-color: #cccccc;

          span {
            color: fade-out(color(black), 0.8);
          }
        }
      }
    }
  }
}
